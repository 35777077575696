<template>
  <div class="main" :style="{width:chartWidth+'px',marginRight:chartRight+'px'}">
    <a-button-group v-if="chartOption.isSaveImage" style="width:100%;">
      <a-button style="width:80px;" type="primary" @click="exportChart">导出图片</a-button>
      <a-button style="width:80px;" type="primary" @click="exportData">导出数据</a-button>
    </a-button-group>
    <div ref="chartPDF" :style="{width:chartWidth+'px',marginRight:chartRight+'px'}">
      <div :style="{fontSize: chartOption.titleFontSize,
fontFamily: 'Microsoft YaHei',fontWeight: 'bold',height: '30px',lineHeight:  '30px',background:'#E6E6E6'}">
        <span :style="{fontSize: chartOption.titleFontSize,
fontFamily: 'Microsoft YaHei',fontWeight: 'bold',height: '30px',lineHeight:  '30px',background:'#E6E6E6',marginLeft: '10px'}"></span>
        {{chartOption.title}}</div>
      <div ref="mainEchart" :style="{width:chartWidth+'px',height:chartHeight+'px'}">
      </div>
      <a-table v-if="this.chartOption.showTable" class="mytable" :columns="tableColumns" :bordered="true" :pagination="false" :style="{width:tableWidth+'px',fontSize:chartOption.xFontSize}"
               :data-source="tableDatas" size="middle"/>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import moment from "moment";
import {exportData} from "@/utils/data-manager";

export default {
  name: 'CatalystChart',
  props: {
    chartWidth: {
      type: Number,
      default: 434
    },
    chartOption: {
      type: Object,
      default: () => {
        return {
          reportId:-1,
          imageFileIds:[],
          dataType:null,
          title: "标题例子",
          model:1,
          yTitle: "Y轴标题",
          yPercent: true,
          xTitle: "X轴标题",
          chartType: "bar",
          xNames: ["<3","3~4", "4~5", "5~6", ">6"],
          dataMap: null,
          seriesNames:null,
          isSaveImage:false,
          isShowSelLabel:true,
          showTable:true
        }
      }
    }
  },
  data() {
    return {
      seriesDatas: [],
      tableColumns: [],
      tableDatas: [],
      myChart: null,
      chartHeight: 100,
      tableWidth:100,
      titleFontSize:24,
      yLeft:70,
      xFontSize:16,
      yFontSize:16,
      chartRight:10
    }
  },
  created() {
    if(this.chartOption.model===1){
      this.chartRight=10;
      this.chartOption.titleFontSize=24;
      this.chartOption.xFontSize=16;
      this.chartOption.yFontSize=16;
      this.chartOption.yLeft=70;
      this.chartHeight=Math.ceil(this.chartWidth*3/5 );
      this.tableWidth=this.chartWidth-10;
    }else if(this.chartOption.model===0){
      this.chartRight=5;
      this.chartOption.titleFontSize=13;
      this.chartOption.xFontSize=10;
      this.chartOption.yFontSize=10;
      this.chartOption.yLeft=70;
      this.chartHeight=Math.ceil(this.chartWidth*4/5 );
      this.tableWidth=this.chartWidth-4;
    }
  },
  mounted() {
    //初始化表格列头
    let columnData = new Object;
    //是否显示“催化剂型号”列
    if(this.chartOption.isShowSelLabel){
      columnData.dataIndex = "name";
      columnData.title = "催化剂型号";
      columnData.align = "center";
      this.tableColumns.push(columnData);
    }
    for (let i = 0; i < this.chartOption.xNames.length; i++) {
      let columnData = new Object;
      columnData.dataIndex = "column" + i;
      columnData.title = this.chartOption.xNames[i];
      columnData.align = "center";
      this.tableColumns.push(columnData);
    }
    this.parseDataMap();
    this.drawChart();
  },
  beforeDestroy() {
    //清除图表对象
    if (this.myChart) {
      echarts.dispose(this.myChart);
      this.myChart = null;
    }
  },
  methods: {
    async exportData() {
      let txtvalue=await exportData(this.chartOption.reportId,this.chartOption.imageFileIds,this.chartOption.dataType);
      if(!txtvalue)
        return;
      const blob = new Blob([txtvalue],{type:"text/plain;charset=utf-8"});
      let date=new Date();
      const fileName = this.chartOption.title+new moment().format("yyyyMMDDhhmmss")+'.csv';
      const link = document.createElement('a');
      link.download = fileName;
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);//,{type:"text/plain;charset='utf-8'"});
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    },
    exportChart(){
      let page=this.$refs.chartPDF;
      html2canvas(page).then(canvas=>{
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = 592.28 / contentWidth * contentHeight;
        let pageData = canvas.toDataURL('image/jpeg', 1.0);
        let PDF = new JsPDF('', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        let reportFileName = this.chartOption.title+new moment().format("yyyyMMDDhhmmss")+".pdf";
        PDF.save(reportFileName);
      }).catch(function(error){
        console.log("导出统计图表pdf出错：",error);
      });
    },
    drawChart() {
      this.myChart = echarts.init(this.$refs.mainEchart);
      //配置图表
      let option = {
        // title: {
        //   text: this.chartOption.title,
        //   textStyle: {
        //     fontSize: this.chartOption.titleFontSize,
        //     fontFamily: 'Microsoft YaHei'
        //   },
        //   x: "center"
        // },
        tooltip: {
          formatter:(value)=>{
            let dataValue=value.data;
            if(this.chartOption.yPercent){
              dataValue=dataValue+"%";
            }
            return value.seriesName+"："+value.name+"值是"+dataValue;
          }
        },
        legend: {
          x: "center",
          y: "bottom"
        },
        grid: {
          left: this.chartOption.yLeft
        },
        xAxis: {
          name: this.chartOption.xTitle,
          nameTextStyle: {
            fontSize: this.chartOption.xFontSize,
            fontFamily: 'Microsoft YaHei'
          },
          boundaryGap:(this.chartOption.chartType==='line'?false:true),
          nameGap: 20,
          nameLocation: 'center',
          data: this.chartOption.xNames
        },
        yAxis: {
          name: this.chartOption.yTitle,
          nameTextStyle: {
            fontSize: this.chartOption.yFontSize,
            fontFamily: 'Microsoft YaHei'
          },
          nameRotate: 90,
          nameGap: this.chartOption.yLeft-30,
          nameLocation: 'middle',
          type: 'value',
          axisLabel: {
            formatter: value => {
              if (this.chartOption.yPercent) {
                return value + '%';
              } else {
                return value;
              }
            }
          }

        },
        series: this.seriesDatas
      };
/*
      if(this.chartOption.isSaveImage){
        option.toolbox={show:true,feature:{saveAsImage:{title:"导出",iconStyle:{borderColor:"blue"}}}};
      }else{
        option.toolbox={show:false};
      }
*/
      this.myChart.setOption(option);
    },
    refreshChart(dataMap) {
      this.chartOption.dataMap = dataMap;
      this.parseDataMap();
      let option = this.myChart.getOption();
      option.series = this.seriesDatas;
      this.myChart.setOption(option,true);
    },
    parseDataMap() {
      //初始化图表数据对象
      if (this.chartOption.dataMap != null) {
        this.seriesDatas = [];
        this.chartOption.dataMap.forEach((value, key) => {
          //设置图表系列数据
          let chartSeries = new Object();
          chartSeries.type = this.chartOption.chartType;
          if(this.chartOption.seriesNames!=null){
            let value=this.chartOption.seriesNames.get(key);
            if(value){
              key=value;
            }
          }
          chartSeries.name = key;
          chartSeries.data = value;
          if(this.chartOption.xTitle=="批次"){
            chartSeries.barWidth="15%";
            chartSeries.label={show:true,position:"top"};
          }
          this.seriesDatas.push(chartSeries);
          // console.log(this.seriesDatas);
          if(this.chartOption.showTable){
            //设置表格数据行
            this.tableDatas = [];
            let rowData = new Object;
            rowData["key"] = key;
            //是否显示“催化剂型号”列
            if(this.chartOption.isShowSelLabel) {
              rowData["name"] = key;
            }
            // console.log("parseDataMap: key is ",key,",value is ",value);
            for (let i = 0; i < value.length; i++) {
              rowData["column" + i] = value[i] + (this.chartOption.yPercent ? "%" : "");
            }
            this.tableDatas.push(rowData);
          }
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.main {
  border: 1px solid #EEEEEE;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-bottom: 10px;
  float: left;
  overflow: hidden;
  box-shadow: 0px 1px 8px 0px rgba(61,74,148,0.3);
  border-radius: 4px;
}

.mytable{
  float:right;
  border: 1px solid #EEEEEE;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 2px !important;
}

/deep/ .ant-table-thead > tr > th {
  padding: 2px !important;
}
</style>
